import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isTokenValid } from './utils/auth';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Checkbox } from "@/components/ui/checkbox";
import { Lock, User } from "lucide-react";
import { env } from "@/utils/env";

const LoginPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (isTokenValid()) {
      navigate('/');
    }
  }, [navigate]);

  // 组件加载时检查是否有保存的登录信息
  useEffect(() => {
    const savedCredentials = localStorage.getItem('rememberedUser');
    if (savedCredentials) {
      try {
        const { username: savedUsername, password: savedPassword, remember: wasRemembered } = JSON.parse(atob(savedCredentials));
        if (wasRemembered) {
          setUsername(savedUsername);
          setPassword(savedPassword);
          setRemember(true);
        }
      } catch (e) {
        localStorage.removeItem('rememberedUser');
      }
    } else {
      setUsername("admin");
    }
  }, []);

  const saveCredentials = (username, password) => {
    if (remember) {
      const credentials = btoa(JSON.stringify({
        username,
        password,
        remember: true
      }));
      localStorage.setItem('rememberedUser', credentials);
    } else {
      localStorage.removeItem('rememberedUser');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (!username || !password) {
      setError('请输入用户名和密码');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(env.hostname+'/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });

      const data = await response.json();

      if (!response.ok || !data.success) {
        throw new Error(data.message || '登录失败');
      }

      // 保存记住的用户信息
      saveCredentials(username, password);
      
      // 存储JWT
      localStorage.setItem('Authorization', "Bearer " + data.message);
      
      // 使用 navigate 进行跳转
      navigate('/');

    } catch (err) {
      setError(err.message || '登录失败，请稍后重试');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen w-full flex items-center justify-center bg-gray-50">
      <Card className="w-full max-w-md mx-4">
        <CardHeader className="space-y-1">
          <CardTitle className="text-2xl text-center">后台管理系统</CardTitle>
          <CardDescription className="text-center">
            请输入您的账号密码登录
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              {error && (
                <Alert variant="destructive">
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
              <div className="space-y-2">
                <Label htmlFor="username">用户名</Label>
                <div className="relative">
                  <User className="absolute left-2 top-2.5 h-5 w-5 text-gray-500" />
                  <Input
                    id="username"
                    placeholder="请输入用户名"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="pl-9"
                    autoComplete="username"
                  />
                </div>
              </div>
              <div className="space-y-2">
                <Label htmlFor="password">密码</Label>
                <div className="relative">
                  <Lock className="absolute left-2 top-2.5 h-5 w-5 text-gray-500" />
                  <Input
                    id="password"
                    type="password"
                    placeholder="请输入密码"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="pl-9"
                    autoComplete="current-password"
                  />
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="remember"
                  checked={remember}
                  onCheckedChange={(checked) => setRemember(checked)}
                />
                <Label
                  htmlFor="remember"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  记住账号密码
                </Label>
              </div>
            </div>
          </form>
        </CardContent>
        <CardFooter>
          <Button 
            className="w-full"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? '登录中...' : '登录'}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default LoginPage;