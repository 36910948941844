import React, { useState, useEffect, useRef, memo } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { Textarea } from "@/components/ui/textarea";
import { Skeleton } from "@/components/ui/skeleton"
import { ChevronDown, ChevronUp, Plus, Copy } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useNavigate } from "react-router-dom";
import { dateAddDays } from "@/utils/date";
import copy from "copy-to-clipboard";
import { env } from "@/utils/env";

// 主页面组件
const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('players');

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto px-4 py-6 max-w-4xl">
        <div className="mb-6 flex gap-2">
          <Button
            onClick={() => setActiveTab('players')}
            variant={activeTab === 'players' ? 'default' : 'outline'}
            className="flex-1"
          >
            玩家列表
          </Button>
          <Button
            onClick={() => setActiveTab('codes')}
            variant={activeTab === 'codes' ? 'default' : 'outline'}
            className="flex-1"
          >
            激活码管理
          </Button>
        </div>

        {activeTab === 'players' ? <PlayersList /> : <ActivationCodes />}
      </div>
    </div>
  );
};

// 玩家列表组件
const PlayersList = () => {
  const [sortDirection, setSortDirection] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingStates, setLoadingStates] = useState({});
  const [error, setError] = useState(null);
  const [players, setPlayers] = useState([]);
  const navigate = useNavigate();
  const size = 4;
  const totalPageRef = useRef(1);
  const latestRequestRef = useRef(1); // 防止过快点击下一页，之前的请求全部抛弃

  const handleToggleStatus = async (player) => {
    setLoadingStates(prev => ({ ...prev, [player.id]: true }));
    try {
      const response = await fetch(env.hostname + `/disableUser`, {
        method: 'PUT',
        headers: {
          'Authorization': localStorage.getItem('Authorization'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "name": player.name, "disable": !player.is_disabled
        })
      });

      if (!response.ok) {
        throw new Error('操作失败');
      }
      const result = await response.json();
      if (!result.success) {
        throw new Error('操作失败');
      }
      player.is_disabled = !player.is_disabled;
      // 刷新列表数据
      // await getPlayers();
    } catch (err) {
      // 可以添加一个错误提示
      console.error(err);
    } finally {
      setLoadingStates(prev => ({ ...prev, [player.id]: false }));
    }
  };

  const getPlayers = async () => {
    latestRequestRef.current = currentPage;
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(env.hostname + `/user?page=${currentPage}&size=${size}&sort=${sortDirection}`, {
        headers: {
          'Authorization': localStorage.getItem('Authorization')
        }
      });
      if (!response.ok) {
        throw new Error('获取数据失败');
      }
      const result = await response.json();
      if(currentPage !== latestRequestRef.current) return;
      if (!result.success) {
        navigate('/login');
      }
      totalPageRef.current = Math.ceil(result.pagination.total / size);
      setPlayers(result.data);
    } catch (err) {
      if(currentPage === latestRequestRef.current)
      setError(err.message);
    } finally {
      if(currentPage === latestRequestRef.current)
      setLoading(false);
    }
  }

  useEffect(() => {
    getPlayers();
  }, [currentPage, sortDirection]);

  const toggleSort = () => {
    const newDirection = sortDirection === 'desc' ? 'asc' : 'desc';
    setSortDirection(newDirection);
  };

  return (
    <div>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between gap-2">
          <CardTitle>玩家列表</CardTitle>
          <Button
            variant="outline"
            size="sm"
            onClick={toggleSort}
            className="flex items-center gap-1"
          >
            按时间{sortDirection === 'desc' ? '倒序' : '正序'}
            {sortDirection === 'desc' ? <ChevronDown className="h-4 w-4" /> : <ChevronUp className="h-4 w-4" />}
          </Button>
        </CardHeader>
        <CardContent>
          {loading ?
            <PlayerLoadingSkeleton length={size} />
            : error ? (
              <div className="text-center py-8 text-red-500">
                {error}
              </div>
            ) : (
              <div className="space-y-4">
                {players.map((player) => (
                  <div key={player.id} className="border rounded-lg p-4">
                    <div className="flex justify-between items-center mb-2">
                      <h3 className="font-medium">{player.name}</h3>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <div className="flex items-center gap-2">
                              <span className="text-sm text-gray-500">
                                {player.is_disabled ? '已禁用' : '已启用'}
                              </span>
                              <div className="relative">
                                <Switch
                                  checked={!player.is_disabled}
                                  onCheckedChange={() => handleToggleStatus(player)}
                                  disabled={loadingStates[player.id]}
                                />
                                {loadingStates[player.id] && (
                                  <div className="absolute inset-0 flex items-center justify-center">
                                    <div className="animate-spin h-4 w-4 border-2 border-primary border-t-transparent rounded-full" />
                                  </div>
                                )}
                              </div>
                            </div>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>切换玩家账号状态</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                    <div className="text-sm text-gray-500 space-y-1">
                      <div className="flex flex-row items-center gap-2 justify-start">
                        <div className="flex items-center gap-2 min-w-0">
                          <span className="flex-shrink-0">激活码:</span>
                          <span className="truncate md:text-clip md:whitespace-normal">
                            {player.code}
                          </span>
                        </div>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="h-6 w-6 flex-shrink-0"
                          onClick={() => {
                            copy(player.code);
                          }}
                        >
                          <Copy className="h-3 w-3" />
                        </Button>
                      </div>
                      <p>最后更新: {player.updated_at}</p>
                      <p>状态: {player.is_in_game ? '游戏中' : '离线'}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPageRef.current}
            onPageChange={setCurrentPage}
          />
        </CardContent>
      </Card>
    </div>
  );

};

// 玩家列表加载骨架屏
const PlayerLoadingSkeleton = memo(({length = 4}) => {
  return (<div className="space-y-4">
    {Array.from({ length }).map((_, index) => (
      <div key={index} className="border rounded-lg p-4 space-y-2">
        <div className="flex flex-row justify-between">
          <Skeleton className="h-6 w-40" />
          <Skeleton className="h-6 w-16" />
        </div>
        <div className="flex flex-row justify-start space-x-2">
          <Skeleton className="h-4 w-60" />
          <Skeleton className="h-4 w-6" />
        </div>
        <Skeleton className="h-4 w-48" />
        <Skeleton className="h-4 w-20" />
      </div>))}
  </div>
  );
});

// 激活码管理组件
const ActivationCodes = () => {
  const [sortDirection, setSortDirection] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [codes, setCodes] = useState([]);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const navigate = useNavigate();
  const size = 4;
  const totalPageRef = useRef(1);
  const latestRequestRef = useRef(1); // 防止过快点击下一页，之前的请求全部抛弃

  const getCodes = async () => {
    latestRequestRef.current = currentPage;
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(env.hostname + `/code?page=${currentPage}&size=${size}&sort=${sortDirection}`, {
        headers: {
          'Authorization': localStorage.getItem('Authorization')
        }
      });
      if (!response.ok) {
        throw new Error('获取数据失败');
      }
      const result = await response.json();
      if(currentPage !== latestRequestRef.current) return; 
      if (!result.success) {
        navigate('/login');
      }
      totalPageRef.current = Math.ceil(result.pagination.total / size);
      setCodes(result.data);
    } catch (err) {
      if(currentPage === latestRequestRef.current)
      setError(err.message);
    } finally {
      if(currentPage === latestRequestRef.current)
      setLoading(false);
    }
  }

  useEffect(() => {
    getCodes();
  }, [currentPage, sortDirection]);

  const toggleSort = () => {
    const newDirection = sortDirection === 'desc' ? 'asc' : 'desc';
    setSortDirection(newDirection);
  };

  return (
    <div>
      <Card>
        <CardHeader>
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-2">
            <CardTitle>激活码管理</CardTitle>
            <div className="flex items-center gap-2 self-end sm:self-auto">
              <Button
                variant="outline"
                size="sm"
                onClick={toggleSort}
                className="flex items-center gap-1"
              >
                按到期时间{sortDirection === 'desc' ? '倒序' : '正序'}
                {sortDirection === 'desc' ? <ChevronDown className="h-4 w-4" /> : <ChevronUp className="h-4 w-4" />}
              </Button>
              <Dialog open={showAddDialog} onOpenChange={setShowAddDialog}>
                <DialogTrigger asChild>
                  <Button size="sm" className="flex items-center gap-1">
                    <Plus className="h-4 w-4" />
                    添加激活码
                  </Button>
                </DialogTrigger>
                <AddCodeDialog
                  onSuccess={async () => {
                    await getCodes();
                    setShowAddDialog(false);
                  }}
                />
              </Dialog>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          {loading ?
          <CodeListLoadingSkeleton length={size} />
          //  (
          //   <div className="flex justify-center items-center py-8">
          //     <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          //   </div>
          // ) 
          : error ? (
            <div className="text-center py-8 text-red-500">
              {error}
            </div>
          ) : (
            <div className="space-y-4">
              {codes.map((code) => (
                <CodeItem key={code.id} code={code} />
              ))}
            </div>
          )}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPageRef.current}
            onPageChange={setCurrentPage}
          />
        </CardContent>
      </Card>
    </div>
  );
};

const CodeItem = ({ code }) => {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});

  const handleToggleStatus = async (code) => {
    setLoadingStates(prev => ({ ...prev, [code.id]: true }));
    try {
      const response = await fetch(env.hostname + `/disableCode`, {
        method: 'PUT',
        headers: {
          'Authorization': localStorage.getItem('Authorization'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "code": code.code, "disable": !code.is_disabled })
      });

      if (!response.ok) {
        throw new Error('操作失败');
      }

      const result = await response.json();
      if (!result.success) {
        throw new Error('操作失败');
      }
      code.is_disabled = !code.is_disabled;
      // 刷新列表数据
      // await getCodes(currentPage);
    } catch (err) {
      // 可以添加一个错误提示
      console.error(err);
    } finally {
      setLoadingStates(prev => ({ ...prev, [code.id]: false }));
    }
  };

  return (<div className="border rounded-lg p-4">
    <div className="flex justify-between mb-2">
      <div className="flex-1 min-w-0"> {/* 添加 min-w-0 防止子元素溢出 */}
        <div className="flex items-center gap-2">
          <h3 className="font-medium truncate md:text-clip md:whitespace-normal">
            {code.code}
          </h3>
          <Button
            variant="ghost"
            size="icon"
            className="h-8 w-8 flex-shrink-0"
            onClick={() => {
              copy(code.code);
              // 可以添加一个提示复制成功的 toast
            }}
          >
            <Copy className="h-4 w-4" />
          </Button>
        </div>
        <div className="text-sm text-gray-500 space-y-1 mt-2">
          {code.actived_at ? (
            <p>激活时间: {code.actived_at}</p>
          ) : (
            <p>状态: 未使用</p>
          )}
          {code.actived_at
            ? (
              <p>到期时间: {code.validity_days >= 99999 ? "永久" : dateAddDays(code.actived_at, code.validity_days).format()}</p>
            ) : (<p>有效期: {code.validity_days >= 99999 ? "永久" : code.validity_days + "天"}</p>)}
        </div>
      </div>
      <div className="flex flex-col gap-2 ml-4 flex-shrink-0 justify-around items-end">
        <Dialog open={showEditDialog} onOpenChange={setShowEditDialog}>
          <DialogTrigger asChild>
            <Button variant="outline" size="sm" className="w-22">修改有效期</Button>
          </DialogTrigger>
          <EditCodeDialog code={code} onSuccess={async () => {
            setShowEditDialog(false);
          }} />
        </Dialog>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center gap-2">
                <span className="text-sm text-gray-500">
                  {code.is_disabled ? '已禁用' : '已启用'}
                </span>
                <div className="relative">
                  <Switch
                    checked={!code.is_disabled}
                    onCheckedChange={() => handleToggleStatus(code)}
                    disabled={loadingStates[code.id]}
                  />
                  {loadingStates[code.id] && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="animate-spin h-4 w-4 border-2 border-primary border-t-transparent rounded-full" />
                    </div>
                  )}
                </div>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>切换激活码状态</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
  </div>);
}

// 激活码列表加载骨架屏
const CodeListLoadingSkeleton = memo(({length = 4}) => {
  return (<div className="space-y-4">
    {Array.from({ length }).map((_, index) => (<div key={index} className="border rounded-lg p-4 flex flex-row justify-between">
      <div className="flex flex-col justify-between gap-2">
        <Skeleton className="h-8 w-40" />
        <Skeleton className="h-6 w-40" />
        <Skeleton className="h-6 w-40" />
      </div>
      <div className="flex flex-col justify-around items-end">
        <Skeleton className="h-8 w-20" />
        <Skeleton className="h-8 w-20" />
      </div>
    </div>))}
  </div>);
});

// 添加激活码对话框组件
const AddCodeDialog = ({ onSuccess }) => {
  const [code, setCode] = useState('');
  const [duration, setDuration] = useState("7");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!code.trim()) return;

    setLoading(true);
    try {
      const codes = code.split('\n')
        .map(line => line.trim())
        .filter(line => line !== '')
        .map(code => {
          let expireTime = parseInt(duration);
          if (isNaN(expireTime)) expireTime = 99999
          return { code, expireTime };
        });

      const response = await fetch(env.hostname + `/addCodes`, {
        method: 'POST',
        headers: {
          'Authorization': localStorage.getItem('Authorization'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(codes)
      });

      if (!response.ok) {
        throw new Error('添加失败');
      }

      const result = await response.json();
      if (!result.success) {
        throw new Error(result.message || '添加失败');
      }

      // 重置表单
      setCode('');
      setDuration('7');
      // 刷新列表
      await onSuccess();
    } catch (err) {
      console.error(err);
      // 这里可以添加错误提示
    } finally {
      setLoading(false);
    }
  };

  return (
    <DialogContent className="sm:max-w-md">
      <DialogHeader>
        <DialogTitle>添加激活码</DialogTitle>
      </DialogHeader>
      <div className="space-y-4 py-4">
        <div className="space-y-2">
          <label className="text-sm font-medium">激活码</label>
          <Textarea
            placeholder="请输入激活码，每行一个"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            className="min-h-[120px]"
            disabled={loading}
          />
        </div>
        <div className="space-y-2">
          <label className="text-sm font-medium">有效期</label>
          <Select value={duration} onValueChange={setDuration} disabled={loading}>
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="1">1天</SelectItem>
              <SelectItem value="7">7天</SelectItem>
              <SelectItem value="30">30天</SelectItem>
              <SelectItem value="90">90天</SelectItem>
              <SelectItem value="365">365天</SelectItem>
              <SelectItem value="permanent">永久</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <Button
          className="w-full"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <div className="flex items-center gap-2">
              <div className="animate-spin h-4 w-4 border-2 border-current border-t-transparent rounded-full" />
              添加中...
            </div>
          ) : (
            "确认添加"
          )}
        </Button>
      </div>
    </DialogContent>
  );
};

// 修改激活码对话框组件
const EditCodeDialog = ({ code, onSuccess }) => {
  const [duration, setDuration] = useState('7');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    let expireTime = parseInt(duration);
    if (isNaN(expireTime)) expireTime = 99999
    try {
      const response = await fetch(env.hostname + `/updateCode`, {
        method: 'PUT',
        headers: {
          'Authorization': localStorage.getItem('Authorization'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          code: code.code,
          validity_days: expireTime
        })
      });

      if (!response.ok) {
        throw new Error('修改失败');
      }

      const result = await response.json();
      if (!result.success) {
        throw new Error(result.message || '修改失败');
      }

      code.validity_days = expireTime;
      await onSuccess();
    } catch (err) {
      console.error(err);
      // 这里可以添加错误提示
    } finally {
      setLoading(false);
    }
  };

  return (
    <DialogContent className="sm:max-w-md">
      <DialogHeader>
        <DialogTitle>修改激活码有效期</DialogTitle>
      </DialogHeader>
      <div className="space-y-4 py-4">
        <div className="space-y-2">
          <label className="text-sm font-medium">激活码</label>
          <p className="text-gray-500">{code.code}</p>
        </div>
        <div className="space-y-2">
          <label className="text-sm font-medium">新的有效期</label>
          <Select value={duration} onValueChange={setDuration} disabled={loading}>
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="1">1天</SelectItem>
              <SelectItem value="7">7天</SelectItem>
              <SelectItem value="30">30天</SelectItem>
              <SelectItem value="90">90天</SelectItem>
              <SelectItem value="365">365天</SelectItem>
              <SelectItem value="permanent">永久</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <Button
          className="w-full"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? (
            <div className="flex items-center gap-2">
              <div className="animate-spin h-4 w-4 border-2 border-current border-t-transparent rounded-full" />
              修改中...
            </div>
          ) : (
            "确认修改"
          )}
        </Button>
      </div>
    </DialogContent>
  );
};

// 分页组件
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className="flex justify-center items-center gap-2 mt-4">
      <Button
        variant="outline"
        size="sm"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        上一页
      </Button>
      <span className="text-sm">
        {currentPage} / {totalPages}
      </span>
      <Button
        variant="outline"
        size="sm"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        下一页
      </Button>
    </div>
  );
};

export default AdminDashboard;