// 解析JWT的工具函数
const parseJwt = (token) => {
    try {
        const base64Url = token.split(' ')[1].split('.')[0];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } catch (e) {
        return null;
    }
};

// 检查token是否有效
export const isTokenValid = () => {
    const token = localStorage.getItem('Authorization');
    if (!token) return false;

    const decodedToken = parseJwt(token);
    if (!decodedToken) return false;

    // 检查token是否过期
    const expiredTime = new Date(decodedToken.iat);
    expiredTime.setSeconds(expiredTime.getSeconds() + decodedToken.exp)
    return expiredTime > Date.now();
};