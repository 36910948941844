import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './LoginPage';
import AdminDashboard from './AdminDashboard';
import { useEffect, useState } from 'react';
import { isTokenValid } from './utils/auth';
import NotFound from './NotFound';

// 路由保护组件
const ProtectedRoute = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        // 直接检查token是否有效
        const valid = isTokenValid();
        setIsAuthenticated(valid);
        if (!valid) {
            localStorage.removeItem('Authorization');
        }
        setLoading(false);
    }, []);

    if (loading) {
        return <div>加载中...</div>;
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
};

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route
                    path="/"
                    element={
                        <ProtectedRoute>
                            <AdminDashboard />
                        </ProtectedRoute>
                    }
                />
                <Route path="/404" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;