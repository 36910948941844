import { Button } from "@/components/ui/button"
import { HomeIcon } from "lucide-react"
import { useNavigate } from "react-router-dom"

export default function NotFound() {
  const navigate = useNavigate()

  return (
    <div className="h-screen w-full flex flex-col items-center justify-center gap-6">
      {/* 404数字显示 */}
      <h1 className="text-9xl font-bold text-muted-foreground">404</h1>
      
      {/* 错误信息 */}
      <div className="text-center space-y-2">
        <h2 className="text-2xl font-semibold">页面未找到</h2>
        <p className="text-muted-foreground">
          抱歉，您访问的页面似乎不存在。
        </p>
      </div>

      {/* 返回首页按钮 */}
      <Button 
        onClick={() => navigate("/")} 
        className="mt-4"
      >
        <HomeIcon className="mr-2 h-4 w-4" />
        返回首页
      </Button>
    </div>
  )
}